import Slide from '../components/SlideShow/Slide';
import { ThumbsUp } from 'react-feather'

function SlideLinks() {

  const body = (
    <section className="verticalRoom">
    <div className="columns six">
      <ThumbsUp className="big" />
    </div>
    <div className="columns six">
    <b>Links</b>
    <ul>
    <li><a href="https://www.elinext.com/blog/modular-web-design/#">modular-web-design</a></li>
    <li><a href="https://sigma.software/about/media/migrating-monolith-microservices-step-step-guide">migrating monolith micro services</a></li>
    <li><a href="https://i.pinimg.com/originals/49/9c/21/499c21e1cfa48222ffebad3c45fde0e7.png">Color Scheme inspiration</a></li>
    <li><a href="https://mermaid-js.github.io">mermaid-js - diagrams as code</a></li>
    <li><a href="https://airbnb.io/javascript/">airbnb JavaScript best practice</a></li>
    <li><a href="https://feathericons.com/">feathericons - a nice set of open source icons</a></li>
    <li><a href="http://getskeleton.com/">getskeleton - boilerplate CSS</a></li>
    </ul>
    </div>
    </section>
  )

  return (
    <Slide header="Thanks for"
    subHeader="listening!"
    body={body}
     />

  );
}

export default SlideLinks;
