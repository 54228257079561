import React from 'react';
import { User, Gitlab, GitHub, ArrowLeft, ArrowRight, CheckSquare } from 'react-feather'

import "./Style.css"
import Timer from '../Timer'

const NextActionKey = "ArrowRight";
const PreviousActionKey = "ArrowLeft";

class SlideShow extends React.Component {
  /*
  Acts as the controller and main window for the slideshow

  TODO:
  - Timer - How long we are taking - Basic done, needs formatting
  - Slide Number - Slide X/Y - Can do a fancy progress later - easy
  - reset presentation
  - Controls - Action button - lives in slides, how do we pass back?
  */

  navigate(change = 1)
  {
    this.slides = React.Children.toArray(this.props.children);
    let nextSlide = this.state.currentSlideIndex + change;
    if(nextSlide >= this.slides.length)
    {
      console.log("Last slide! TODO: event?")
    }
    else if(nextSlide < 0)
    {
      console.log("First slide! Reset?")
    }
    else
    {
      this.setState(
        {
          currentSlide: this.slides[nextSlide],
          currentSlideIndex: nextSlide
        }
      );
    }
  }

  nextSlide()
  {
    this.navigate();
  }

  previousSlide()
  {
    this.navigate(-1);
  }

  handleKeyPress(e)
  {
    console.log(NextActionKey);

    if(e.key === NextActionKey)
    {
      this.nextSlide();
    }
    else if(e.key === PreviousActionKey)
    {
      this.previousSlide();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentSlide: null,
      isPlaying: false
    }
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
  }

  componentDidMount() {
    this.slides = React.Children.toArray(this.props.children);
    this.setState(
      {
        currentSlide: this.slides.length > 0 ? this.slides[0] : (<p>No slide</p>),
        currentSlideIndex:this.slides.length > 0 ? 0 : -1
      }
    )
    document.body.addEventListener('keyup',this.handleKeyPress);
  }

  componentWillUnmount()
  {
    document.body.removeEventListener('keyup',this.handleKeyPress);
  }

  render(){

    let repo = "";
    if(this.props.repo)
    {
      if(this.props.repo.includes("gitlab"))
      {
        repo = (<a href={this.props.repo} target="_blank" className="iconText" rel="noreferrer"><Gitlab />Source</a>)
      }
      else if(this.props.repo.includes("github"))
      {
        repo = (<a href={this.props.repo} target="_blank" className="iconText" rel="noreferrer"><GitHub />Source</a>)
      }
      else
      {
        repo = (<a href={this.props.repo} target="_blank" className="iconText" rel="noreferrer">Source</a>)
      }
    }

    return(
      <div className="slideshow">
        <header>
        <b className="iconText"><User />{this.props.author}</b>
        {repo}
        <Timer />
        </header>
        <nav>
        <button className="navButton" disabled={this.currentSlideIndex > 0 && "true"} onClick={this.previousSlide} ><ArrowLeft /></button>
        <button className="navButton"><CheckSquare /></button>
        <button className="navButton"><ArrowRight onClick={this.nextSlide} /></button>
        </nav>
        {this.state.currentSlide}
      </div>
    );
  }
}

export default SlideShow;
