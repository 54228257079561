import SlideShow from '../components/SlideShow/SlideShow';
import SlideOne from '../slides/slide-1-intro';
import SlideTwo from '../slides/slide-2-morph-mono';
import SlideThree from '../slides/slide-3-morph-many';
import SlideFour from '../slides/slide-4-why-change';
import SlideFive from '../slides/slide-5-change-how';
import SlideSix from '../slides/slide-6-change-plan';
//import SlideSeven from '../slides/slide-7-show-change';
import SlideQuestions from '../slides/slide-questions';
import SlideLinks from '../slides/slide-links';


function Presentation(props) {
  return (
    <SlideShow author="Jon Keatley" repo="https://gitlab.com/Jon.Keatley.Folio/monolith-to-modular">
      <SlideOne />
      <SlideTwo />
      <SlideThree />
      <SlideFour />
      <SlideFive />
      <SlideSix />
      <SlideQuestions />
      <SlideLinks />
    </SlideShow>
  );
}

export default Presentation;
