import React from 'react';
//import { Smile } from 'react-feather'
import {Smile, Meh, Frown } from 'react-feather'

class Timer extends React.Component {
  /*
  Basic timer presentation component - we should make this a count down!
  */
  constructor(props) {
    super(props);
    this.state = {
      minutes:props.minutes? props.minutes:0,
      seconds:props.seconds? props.seconds:0
    }
  }

  componentDidMount() {
    this.elapsedTimeInterval = setInterval(
      () => this.increment(1),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.elapsedTimeInterval);
  }


  increment(amount = 1){
    let seconds = this.state.seconds;
    let minutes = this.state.minutes;

    seconds += amount;
    while(seconds >= 60)
    {
      minutes += amount;
      while(minutes >= 60)
      {
        minutes -= 60;
      }
      seconds -= 60;
    }

    this.setState(
      {
          seconds,
          minutes
      }
    )
  }

  makeDoubleDigit(val)
  {
    let varStr = String(val);
    if(varStr.length === 1)
    {
      return `0${varStr}`;
    }
    return varStr;
  }

  render(){
    const seconds = this.makeDoubleDigit(this.state.seconds);
    const minutes = this.makeDoubleDigit(this.state.minutes);

    let emote = (<Smile />)
    if(minutes > 4 && minutes < 8)
    {
      emote = (<Meh />)
    }
    else if(minutes >= 8)
    {
      emote = (<Frown />)
    }

    return(<div className="iconText">{emote} Time {minutes}:{seconds} </div>);
  }
}

export default Timer;
