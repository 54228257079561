import React from 'react';
import { Award } from 'react-feather'

import Slide from '../components/SlideShow/Slide';

const numberOfStages = 12;

const stageOne = (
  <div className="slideSixDetails">
  Build the bones
  <ul>
    <li>Details component types</li>
    <li>Design how the various components will integrate.</li>
    <li>Design and build the orchestration layer.</li>
    <li>Design the deployment system and environments.</li>
    <li>Build template repos for each component type.</li>
  </ul></div>)

const stageTwo = (
  <div className="slideSixDetails">
  Review monolith
  <ul>
    <li>List the active features.</li>
    <li>Group related features.</li>
  </ul></div>);

const stageThree = (
  <div className="slideSixDetails">Select a group for migration
  <ul>
    <li>Which group has the most requested changes?</li>
    <li>Which group causes the most pain to maintain?</li>
    <li>Create passing BDD tests for the existing feature set.</li>
  </ul></div>);

const stageFour =(
  <div className="slideSixDetails">Migrate backend
  <ul>
    <li>Use a template to create the framework.</li>
    <li>Recreate the business logic and data layers.</li>
    <li>Use the previously created BDD tests to ensure feature parity.</li>
  </ul></div>);

const stageFive =(
  <div className="slideSixDetails">Replace part of the monolith
  <ul>
    <li>Update monolith to interact with new services.</li>
    <li>Use a feature flag to disable the old code so changes can be rolled back if needed.</li>
  </ul>
  </div>);

const stageSix = (
  <div className="slideSixDetails">Validate
  <ul>
    <li>Run regression test suite.</li>
    <li>User acceptance tests.</li>
  </ul>
  </div>);

const stageSeven = (
  <div className="slideSixDetails">Release
  <ul>
    <li>Promote the service to production.</li>
    <li>Great work! <Award /></li>
  </ul></div>);

const stageEight = (
  <div className="slideSixDetails">Migrate frontend
  <ol>
    <li>Depending on what we are migrating this will either be a SPA or a set of components.</li>
    <li>Use a SPA or component template to create the framework.</li>
    <li>Recreate feature set</li>
  </ol>
  </div>);

const stageNine = (
  <div className="slideSixDetails">Replace part of the monolith
  <ul>
    <li>If the frontend is a SPA then update the monolith to redirect to the new SPA.</li>
    <li>If the frontend is a set of components then integrate them into the monolith's pages.</li>
  </ul>
  </div>);

const stageTen =(
  <div className="slideSixDetails">Validate
  <ul>
    <li>Run regression test suite.</li>
    <li>User acceptance tests.</li>
  </ul></div>);

const stageEleven = (
  <div className="slideSixDetails">Release
  <ul>
    <li>Promote the service to production.</li>
    <li>Great work! <Award /></li>
    <li>You have successfully migrated a section of the monolith.</li>
  </ul></div>);

  const stageTwelve = (
    <div className="slideSixDetails">Repeat!
    <ul>
      <li>Depending on business needs you might want to extend the feature set of the new service.</li>
      <li>If not then it is time to re-evaluate the monolith and to select a new feature group to migrate.</li>
    </ul></div>);

const stageText = [
  "",
  stageOne,
  stageTwo,
  stageThree,
  stageFour,
  stageFive,
  stageSix,
  stageSeven,
  stageEight,
  stageNine,
  stageTen,
  stageEleven,
  stageTwelve
];

class SlideSix extends React.Component {

  slideEvents()
  {
    //recieve click event and act
    let stage = this.state.stage + 1;
    if(stage > numberOfStages)
    {
      stage = 0
    }
    this.setState({
      stage
    })
  }

  constructor(props)
  {
    super(props);
    this.slideEvents = this.slideEvents.bind(this);

    this.state = {
      stage:0
    }
  }

  render()
  {
    const selectedCircle = this.state.stage;
    const selectedLine = selectedCircle - 1;
    const body = (
      <section>
      <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="35 80 140 70"
         className="centralImage"
         >
          <circle
             className={selectedCircle > 0 ? "progress selected":"progress" }
             id="path10"
             cx="65.484375"
             cy="85.7491"
             r="2.4568453" />
          <circle
            className={selectedCircle > 1 ? "progress selected":"progress"}
             id="path10-3"
             cx="65.484375"
             cy="112.54762"
             r="2.4568453" />
           <circle
             className={selectedCircle > 2 ? "progress selected":"progress"}
              id="path10-3-5"
              cx="92.037201"
              cy="-112.54762"
              r="2.4568453"
              transform="scale(1,-1)" />
          <circle
            className={selectedCircle > 3 ? "progress selected":"progress"}
             id="path10-3-5-5"
             cx="117.73959"
             cy="112.54762"
             r="2.4568453" />
           <circle
             className={selectedCircle > 4 ? "progress selected":"progress"}
              id="path10-3-5-5-9"
              cx="144.61713"
              cy="112.54762"
              r="2.4568453" />
          <circle
            className={selectedCircle > 5 ? "progress selected":"progress"}
             id="path10-3-5-6"
             cx="167.59464"
             cy="128.98958"
             r="2.4568453" />
         <circle
           className={selectedCircle > 6 ? "progress selected":"progress"}
            id="path10-3-5-5-9-2"
            cx="143.9256"
            cy="144.86459"
            r="2.4568453" />
          <circle
            className={selectedCircle > 7 ? "progress selected":"progress"}
             id="path10-3-5-5-0"
             cx="117.04807"
             cy="144.86459"
             r="2.4568453" />
         <circle
           className={selectedCircle > 8 ? "progress selected":"progress"}
            id="path10-3-5-3"
            cx="91.34568"
            cy="-144.86459"
            r="2.4568453"
            transform="scale(1,-1)" />
        <circle
          className={selectedCircle > 9 ? "progress selected":"progress"}
           id="path10-3-0"
           cx="64.792854"
           cy="144.86459"
           r="2.4568453" />
         <circle
           className={selectedCircle > 10 ? "progress selected":"progress"}
            id="path10-3-5-6-9"
            cx="-42.115177"
            cy="129.09596"
            r="2.4568453"
            transform="scale(-1,1)" />
          <path
             className={selectedLine > 0 ? "progress selected":"progress"}
             d="M 65.484369,90.807156 V 107.67854"
             id="path4562-7" />
           <path
              className={selectedLine > 1 ? "progress selected":"progress"}
              d="m 71.175627,112.54762 h 16.87139"
              id="path4562" />
          <path
             className={selectedLine > 2 ? "progress selected":"progress"}
             d="M 96.074721,112.54762 H 112.94611"
             id="path4562-3"/>
          <path
             className={selectedLine > 3 ? "progress selected":"progress"}
             d="m 122.95223,112.54762 h 16.87138"
             id="path4562-3-2"/>
         <path
            className={selectedLine > 4 ? "progress selected":"progress"}
            d="m 149.50682,112.16964 c 17.76488,0 17.70718,11.7797 17.70718,11.7797"
            id="path3755"/>
          <path
             className={selectedLine > 5 ? "progress selected":"progress"}
             d="m 149.50682,145.21879 c 17.76488,0 17.70718,-11.7797 17.70718,-11.7797"
             id="path3755-1"  />
         <path
            className={selectedLine > 6 ? "progress selected":"progress"}
            d="m 122.26071,144.86458 h 16.87138"
            id="path4562-3-2-6"/>
          <path
             className={selectedLine > 7 ? "progress selected":"progress"}
             d="M 95.383202,144.86458 H 112.25459"
             id="path4562-3-6"/>
           <path
              className={selectedLine > 8 ? "progress selected":"progress"}
              d="m 70.484112,144.86458 h 16.87139"
              id="path4562-9"/>
          <path
             className={selectedLine > 9 ? "progress selected":"progress"}
             d="m 60.202974,145.32517 c -17.76488,0 -17.70718,-11.7797 -17.70718,-11.7797"
             id="path3755-1-2"/>
           <path
              className={selectedLine > 10 ? "progress selected":"progress"}
              d="m 60.202974,112.27602 c -17.76488,0 -17.70718,11.7797 -17.70718,11.7797"
              id="path3755-7"  />
      </svg>

      {stageText[this.state.stage]}

      </section>
    )

    return (
      <Slide header="Migration"
      subHeader="From 10,000ft"
      body={body}
      actions={this.slideEvents}
      />

    );
  }
}

export default SlideSix;
