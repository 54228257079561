import Slide from '../components/SlideShow/Slide';

function SlideFour() {

  const body = (
    <section>
      <ul>
        <li>Increased flexibility.</li>
        <li>Increased opportunities to scale.</li>
        <li>Increased deployment opportunities.</li>
        <li>Reduced development time for new features.</li>
        <li>Reduced cost of ownership through decoupling.</li>
      </ul>
    </section>
  )

  return (
    <Slide header="Why"
    subHeader="migrate from a monolith?"
    body={body}
     />

  );
}

export default SlideFour;
