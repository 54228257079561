
import { HelpCircle } from 'react-feather'
import Slide from '../components/SlideShow/Slide';

function SlideQuestions() {

  const body = (
    <section>
    <HelpCircle className="big" />
    </section>
  )

  return (
    <Slide header="Any Questions"
    subHeader="?"
    body={body}
     />

  );
}

export default SlideQuestions;
