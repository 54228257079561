import Slide from '../components/SlideShow/Slide';
import './slideStyle.css'

function SlideThree() {

  const body = (
    <section>
    <div className="seven columns">
        <img src="/images/many.svg" className="one-half-image" alt="An example of a modular web application" />
    </div>
    <div className="five columns">
    Phenotypes
      <ul>
        <li>Flexible / no fixed pattern.</li>
        {/* Client */}
        <li>Platform specific clients / multiple clients.</li>
        <li>Component based design.</li>
        <li>Client side - improved tooling / increased complexity.</li>
        {/* Servers */}
        <li>Micro Service / Serverless / event based.</li>
        <li>RESTful / graphQL.</li>
        <li>Dependency inversion.</li>
        <li>Stateless where possible.</li>
        {/* Architecture */}
        <li>Containerised / Orchestration.</li>
        <li>Backend for frontend / API gateways.</li>
        <li>Horizontal scaling / Content delivery networks / Cloud.</li>
        <li>CI/CD IaC.</li>
      </ul>
    </div>
    </section>
  )

  return (
    <Slide header="Morphology:"
    subHeader="What is a modern modular web application?"
    body={body}
     />

  );
}

export default SlideThree;
