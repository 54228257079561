import React from 'react';
import Slide from '../components/SlideShow/Slide';

const sections = [
  "bigbang",
  "incremental",
  "sidecar"
];

const bigbang = (
  <ul>
    <li>The old system is put on maintenance mode while the new system is developed. </li>
    <li>The new system does not go live until it has functional parity with the old.</li>
    <li>Takes a long time to complete.</li>
    <li>No new features until the migration is completed.</li>
    <li>High risk of business needs may change during migration.</li>
  </ul>
)

const sidecar = (
  <ul>
  <li>The new system is developed in parallel to maintaining the old system.</li>
  <li>Parts of the old system are recreated in the new.</li>
  <li>New features are only implemented on the new system. So during migration of a section there is a feature freeze on the rest of the system.</li>
  <li>As features are added to the new system the old system is updated to redirect to the new.</li>
  <li>My preferred strategy.</li>
  </ul>
)

const incremental = (
  <ul>
  <li>The old system is modularised.</li>
  <li>Once modularised the modules are moved into their own services.</li>
  <li>Once a module is split out into a service it is modernised and new client components made for it.</li>
  <li>Requires tackling technical debt and architectural weaknesses in existing codebase.</li>
  <li>Multiple refactoring passes.</li>
  </ul>
)

const sectionDetails = [
  bigbang,
  incremental,
  sidecar
]

class SlideFive extends React.Component {

  slideEvents()
  {
    //recieve click event and act
    const next = this.state.next;
    const after = sections.length > next + 1? next + 1 : 0;
    this.setState({
      selected:sections[next],
      next: after,
      details: sectionDetails[next]
    })
  }

  constructor(props)
  {
    super(props);
    this.slideEvents = this.slideEvents.bind(this);

    this.state = {
      selected:"none",
      next:0
    }
  }

  render()
  {
    const classNames = [
      this.state.selected === "bigbang"?"selected":"unselected",
      this.state.selected === "incremental"?"selected":"unselected",
      this.state.selected === "sidecar"?"selected":"unselected"
    ]

    const body = (
      <section>
      <div className="four columns">
        <ul>
          <li className={classNames[0]}>The big bang approach.</li>
          <li className={classNames[1]}>Incremental replacement.</li>
          <li className={classNames[2]}>Side car.</li>
        </ul>
      </div>
      <div className="eight columns">
      { this.state.details }
      </div>

      </section>
    )

    return (
      <Slide header="Migration"
      subHeader="options"
      body={body}
      actions={this.slideEvents}
       />

    );
  }
}

export default SlideFive;
