import Slide from '../components/SlideShow/Slide';
import { Briefcase, ChevronRight, Layers, MinusSquare } from 'react-feather'
function SlideOne() {

  const body = (
    <section>

    <div className="container verticalRoom">
    <MinusSquare className="icon-list" />
    <ChevronRight className="icon-list" />
    <Briefcase className="icon-list" />
    <ChevronRight className="icon-list" />
    <Layers className="icon-list" />
    </div>
    <div className="verticalRoom prettyB">
    <p><b>Brief:</b> Strategy for migrating a legacy monolith web application to a modern modular web application.</p>
    <p><b>Author:</b> Jon Keatley</p>
    <p><b>Created:</b> 2022/08/27</p>
    </div>
    </section>
  )

  return (
    <Slide header="Mono to Many"
    subHeader="Migrating a legacy monolith web application"
    body={body}
     />

  );
}

export default SlideOne;
