import React from 'react';
import Slide from '../components/SlideShow/Slide';
import './slideStyle.css'

function SlideTwo() {
  const body = (
    <section>
      <div className="seven columns">
          <img src="/images/monolith.svg" className="one-half-image" alt="An example of a monolithic web application" />
      </div>
      <div className="five columns">
      Phenotypes
        <ul>
          <li>Single codebase.</li>
          <li>Template driven.</li>
          <li>Server side rendering.</li>
          <li>Initially fast to develop.</li>
          <li>Good fit for a small development team.</li>
          <li>Reduced security concerns due to single codebase.</li>
          <li>Difficult to scale.</li>
          <li>Limited deployment options.</li>
          <li>Architecture tends to degrade over time.</li>
          <li>Even small changes require redeploying the entire application.</li>
        </ul>
      </div>
    </section>
  )

  return (
    <Slide header="Morphology:"
    subHeader="What is a monolith?"
    body={body}
     />

  );
}

export default SlideTwo;
