import React from 'react';
import { useSpring, animated } from 'react-spring'

function AnimatedHeader(props) {
  const styles = useSpring({
    config:{ duration: 500 },
    loop: false,
    to:{ opacity: 1, color: 'var(--impact)' },
    from: { opacity: 0, color: 'var(--hints)' },
  })

  return <animated.span className="slideHeader" style={styles}>{props.children}</animated.span>
}

function AnimatedSubHeader(props) {
  const styles = useSpring({
    config:{ duration: 500 },
    loop: false,
    to:{ opacity: 1, color: 'var(--highlight)' },
    from: { opacity: 0, color: 'var(--hints)' },
  })

  return <animated.span className="slideSubHeader" style={styles}>{props.children}</animated.span>
}

function AnimatedBlock(props) {
  const styles = useSpring({
    config:{ duration: 500 },
    loop: false,
    to:{ opacity: 1 },
    from: { opacity: 0 },
  })

  return <animated.div style={styles} {...props} >{props.children}</animated.div>
}

class Slide extends React.Component {
  /*
  Abstract class that is used to hold the common elements of a slide
  - support different layout types
  */

  constructor(props)
  {
    super(props)
    this.slideEvent = this.slideEvent.bind(this);
  }

  slideEvent() {

    if(this.props.actions)
    {
      this.props.actions();
      return false;
    }
    else {
        console.log("default slide action")
    }

  }

  render(){
    return(
      <article className="slide" onClick={this.slideEvent}>
        <AnimatedHeader>{this.props.header}</AnimatedHeader><AnimatedSubHeader>{this.props.subHeader}</AnimatedSubHeader>
        <AnimatedBlock className="slideBody">
        {this.props.body}
        </AnimatedBlock>
      </article>
    );
  }
}

Slide.defaultProps = {
  header:"A Slide",
  subHeader:"",
  body:"Say something good"
}

export default Slide;
